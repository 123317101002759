import { useCookies } from "react-cookie";

import { useEffect } from 'react';

export default function Logout() {
    const [cookies, setCookie, removeCookie] = useCookies();

    const Logouts = async () =>{
       // await removeToken()
       removeCookie('token');
       window.location.href = '/';


    }


    useEffect(() => {
        Logouts()
    }, []);
    return (

        <>Logoout Success</>
    )
}
