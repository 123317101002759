import { Box } from "@mui/material";
import { Outlet, Link } from "react-router-dom";

export default function AuthLayout() {
    return (
        <div className="App">
            <div className='body'>
           

                    <Outlet></Outlet>
               

              
            </div>
        </div>
    )
}