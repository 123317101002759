import { Box, Breadcrumbs, Button, Container, Grid, Link, MenuItem, Paper, Select, Stack, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Typography } from "@mui/material";
import { useForm } from 'react-hook-form';
import axios from "axios";
import { config } from "../../data/config";
import Swal from 'sweetalert2'
import { useCookies } from 'react-cookie';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import TabContext from '@mui/lab/TabContext';
import SingleFileuploader from "../../component/singlefileuploader";
import MultiFileUploader from "../../component/mulifuleuploader";
import Pagination from '@mui/material/Pagination';
import { useState } from 'react';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ChunkUploader from "../../component/chunkUploader";

import * as React from 'react';
import { CheckBox, Print, QrCode } from "@mui/icons-material";
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
export default function EditEstate() {
    let { Id } = useParams();
    const [pageNumber, setPageNumber] = useState(1);

    const [cookies, setCookie] = useCookies(['token', 'rule']);
    const [data, setData] = React.useState(null);
    const [value, setValue] = React.useState(1);
    const [listcat, setListCat] = React.useState(null);
    const [ListCity, setListCity] = React.useState(null);
    const [Featurelist, setFeaturelist] = React.useState(null);
    const [FeatureItems, setFeature] = React.useState(null);
    const [page, setPage] = React.useState(1);

    const [Listofbarcode, setListofbarcode] = React.useState(null);
    const [PDF, setPDF] = React.useState(null);

    const [page2, setPage2] = React.useState(1);
    const [ListBarcode, setListBarcode] = React.useState(null);
    const [key4, setKey4] = React.useState(0);
    const [ListArea, setListArea] = React.useState(null);
    const [ListNeighborhood, setListNeighborhood] = React.useState(null);

    const [numPages, setNumPages] = React.useState(null);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [PDFS, setupPDF] = React.useState(null);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const load = async () => {
        loadcategory()
        loadcity()
        try {
            var path = `${config.url}/estate/${Id}`;

            let res = await axios({
                method: 'get',
                url: path,
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': `multipart/form-data`,
                    'Authorization': cookies.token
                }
            });
            setData(res.data.message)
            setFeaturelist(res.data.feature)
            setFeature(res.data.message.FeatureItems != null ? res.data.message.FeatureItems : {})
            if (res.data.message.city != null) {
                loadcitlist(res.data.message.city)
            }
            if (res.data.message.zone != null) {
                ZoneLoader(res.data.message.zone)
            }

        } catch (error) {
            Swal.fire('No access edit this estate')

        }
    }
    const loadcategory = async () => {
        var path = `${config.url}/allcat`;
        try {
            let res = await axios({

                url: path,
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': `multipart/form-data`,
                }

            });
            //   setListArea(res.data.message)

        } catch (error) {
            console.log(error);
            alert('error load group')
        }
    }
    const loadcity = async () => {
        var path = `${config.url}/citylist`;
        try {
            let res = await axios({

                url: path,
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': `multipart/form-data`,
                }

            });
            setListCity(res.data.message)

        } catch (error) {
            console.log(error);
        }
    }
    async function ZoneLoader(e) {
        var path = `${config.url}/neightborlist?parent=${e}`;
        try {
            let res = await axios({

                url: path,
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': `multipart/form-data`,
                }

            });
            setListNeighborhood(res.data.message)

        } catch (error) {
            console.log(error);
            alert('error load group')
        }
    }


    async function setZoneChange(e) {
        setData({ ...data, zone: e })
        ZoneLoader(e)



    }
    async function Zoneloader() {

    }
    async function loadcitlist(e) {
        var path = `${config.url}/arealist?parent=${e}`;
        try {
            let res = await axios({

                url: path,
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': `multipart/form-data`,
                }

            });
            setListArea(res.data.message)
            console.log("res.data.message")

            console.log(res.data.message)



        } catch (error) {
            console.log(error);
            alert('error load group')
        }
    }
    async function setCityChange(e) {

        setData({ ...data, city: e })
        loadcitlist(e)
        //arealist

    }
    const submit = async (data2) => {
        var path = `${config.url}/estate/${data['_id']['$oid']}`;
        data.FeatureItems = FeatureItems;
        //FeatureItems
        try {
            let res = await axios({
                method: 'put',
                url: path,
                data: data,
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': `application/json`,
                    'Authorization': cookies.token
                }
            });
            Swal.fire('ذخیره سازی')
            //  window.location.href = '/dashboard/feature';


        } catch (error) {
            console.log('error')
            Swal.fire(error.response.data.message)
            error.response.data.error == null ?
                Swal.fire(error.response.data.message)
                :
                Swal.fire({
                    title: error.response.data.message,
                    html: `<div>
                ${Object.keys(error.response.data.error).map((field) => (
                        ` <div key=${field}>
                 ${error.response.data.error[field].map((message) => (
                            `<a style="font-size:15px"  key={message}> ${message}</a>`
                        ))}
               </div>`
                    ))}
              </div>`

                })
        }

    }
    const handleChangePage = (event, newPage) => {

        setPage(newPage);
        reloadbarcode();

    }


    const reloadbarcode = async (mypage=1) => {
        var path = `${config.url}/pdf_view/${data['_id']['$oid']}?page=${mypage}`;
        try {
            let res = await axios({
                method: 'get',
                url: path,
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': `application/json`,
                    'Authorization': cookies.token
                }
            });
            setListofbarcode(res.data)
        } catch (error) {

        };
    }
    const loadrpint = async (id) => {
        var path = `${config.url}/pdf_show/${id}`;
        try {
            let res = await axios({
                method: 'get',
                data: {},
                url: path,
                responseType: 'blob', // had to add this one here

                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': `application/json`,
                    'Authorization': cookies.token,
                }
            });

            const content = res.headers['content-type'];
            // download(res.data, id, content)
            setupPDF(res.data)
        } catch (error) {

        };
    }
    const handleChangePage2 = (event, newPage) => {
        setPage2(newPage);

        reloadbarcode(newPage)
    }
    const barcodeapi = async (Pages = 1) => {
        var path = `${config.url}/barcodeapi/${Id}?page=${Pages}`;

        try {
            let res = await axios({
                method: 'get',

                url: path,

                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': `application/json`,
                    'Authorization': cookies.token,
                }
            });
            console.log(res.data);
            // const content = res.headers['content-type'];
            // download(res.data, id, content)
            setListBarcode(res.data)
            // var x= key4+1;
            // setKey4(x);
        } catch (error) {

        };
    }
    const createbarcode = () => {
        Swal.fire({
            title: "تعداد بارکد های مورد نیاز ",
            input: "number",
            inputAttributes: {
                autocapitalize: "off"
            },
            showCancelButton: true,
            confirmButtonText: "Create",
            cancelButtonText: 'Close',
            setValue: 0,
            showLoaderOnConfirm: true,
            preConfirm: async (login) => {
                if (login == 0) {
                    Swal.fire('عدد وارد نمایید');
                    return false;
                }
                var path = `${config.url}/pdf_api/${data['_id']['$oid']}?count=${login}`;
                try {
                    let res = await axios({
                        method: 'get',
                        url: path,

                        headers: {
                            'Access-Control-Allow-Origin': '*',
                            'Content-Type': `application/json`,
                            'Authorization': cookies.token
                        }
                    });
                    reloadbarcode();
                } catch (error) {

                };

            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {

        });
    }

    useEffect(() => {
        load();

    }, []);

    return (
        <Container dir={'rtl'}>
            <Box pb={3} pt={3}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/dashboard">
                        داشبورد
                    </Link>
                    <Typography color="text.primary"> بارکد و ویرایش</Typography>
                </Breadcrumbs>
                <Typography variant='h1' color={'gray'} fontSize={34}>ویرایش و بارکد</Typography>
            </Box>
            <Paper>
                <Box>
                    {data != null &&
                        <form onSubmit={handleSubmit((data) => submit(data))} >
                            <Box sx={{ width: '100%' }}>
                                <TabContext value={value}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                            <Tab label="عمومی" value="1" />
                                            <Tab label="توضیحات" value="2" />
                                            <Tab label="عکس اصلی" value="4" />
                                            <Tab label="گالری" value="5" />
                                            <Tab label="ساخت بارکد" value="6" onClick={(e) => {{
                                                setPage2(1)
                                                reloadbarcode()
                                            }  }} />
                                            <Tab label="لیست بارکد" value="7" onClick={(e) => { barcodeapi() }} />

                                        </Tabs>
                                    </Box>
                                    {

                                        value == 1 && <Box value="1">
                                            <Container>
                                                <Box p={1}>
                                                    <Typography variant="h5" color={'gray'}>عمومی</Typography>
                                                </Box>
                                                <Box mt={2}>
                                                    <TextField
                                                        autoComplete={false}
                                                        fullWidth
                                                        defaultValue={data.name}
                                                        onChange={(e) => setData({ ...data, name: e.target.value })}
                                                        required variant={'filled'} label="نام" type="text"
                                                    ></TextField>
                                                </Box>
                                                <Box mt={2}>
                                                    <Grid container>
                                                        <Grid xs={12} sm={3} p={2}>
                                                            <TextField
                                                                autoComplete={false}
                                                                fullWidth
                                                                defaultValue={data.price}
                                                                onChange={(e) => setData({ ...data, price: e.target.value })}
                                                                variant={'filled'} label="قیمت" type="text"
                                                            ></TextField>
                                                        </Grid>
                                                        <Grid xs={12} sm={3} p={2}>
                                                            <TextField
                                                                autoComplete={false}
                                                                fullWidth
                                                                defaultValue={data.bed}
                                                                onChange={(e) => setData({ ...data, bed: e.target.value })}
                                                                variant={'filled'} label="کد" type="text"
                                                            ></TextField>
                                                        </Grid>

                                                        <Grid xs={12} sm={3} p={2}>
                                                            <TextField
                                                                autoComplete={false}
                                                                fullWidth
                                                                defaultValue={data.year}
                                                                onChange={(e) => setData({ ...data, year: e.target.value })}
                                                                variant={'filled'} label="کمپانی" type="text"
                                                            ></TextField>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Container>
                                        </Box>
                                    }
                                    {
                                        value == 2 && <Box value="2">
                                            <Container>
                                                <Box p={1} >
                                                    <Typography variant="h5" color={'gray'}>توضیحات</Typography>
                                                    <CKEditor
                                                        editor={ClassicEditor}
                                                        data={data.text}
                                                        onReady={editor => {
                                                            // You can store the "editor" and use when it is needed.
                                                            console.log('Editor is ready to use!', editor);
                                                        }}
                                                        onChange={(event, editor) => {
                                                            const mydata = editor.getData();
                                                            setData({ ...data, text: mydata })
                                                        }}
                                                        onBlur={(event, editor) => {
                                                            console.log('Blur.', editor);
                                                        }}
                                                        onFocus={(event, editor) => {
                                                            console.log('Focus.', editor);
                                                        }}
                                                    />
                                                </Box>
                                            </Container>
                                        </Box>
                                    }

                                    {
                                        value == 4 && <Box value="4">
                                            <Container>
                                                <Typography variant="h5" color={'gray'}>عکس اصلی</Typography>
                                                <SingleFileuploader component="esateaminimage" parent={data['_id']['$oid']} />
                                            </Container>
                                        </Box>
                                    }
                                    {
                                        value == 5 && <Box value="5">
                                            <Container>
                                                <Typography variant="h5" color={'gray'}>سایر عکس ها </Typography>
                                                <MultiFileUploader component="multifilemanager" parent={data['_id']['$oid']} />
                                            </Container>
                                        </Box>
                                    }
                                    {
                                        value == 7 && <Box value="7">
                                            <Container >
                                                <Box pt={2}>
                                                    <Typography variant="h5" color={'gray'}>لیست کدهای ساخته شده </Typography>
                                                </Box>
                                                <Box>
                                                    <Button onClick={(e) => { barcodeapi() }}>دوباره خوانی</Button>
                                                </Box>

                                                <Box >
                                                    {ListBarcode != null && <Box>
                                                        <TableContainer component={Paper}>
                                                            <Table sx={{ minWidth: 650 }} aria-label="caption table">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell align="right">ستون</TableCell>
                                                                        <TableCell align={'center'}>تعداد </TableCell>
                                                                        <TableCell align={'center'}>ساخت</TableCell>
                                                                        <TableCell align={'center'}>لینک</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {ListBarcode.docs.map((item, index) => {
                                                                        var mydate = new Date(item.date['$date'])
                                                                        return (
                                                                            <TableRow >
                                                                                <TableCell align="right" component="th" scope="row">
                                                                                    {index + 1}
                                                                                </TableCell>
                                                                                <TableCell align={'center'} component="th" scope="row">
                                                                                    {item.countview}
                                                                                </TableCell>

                                                                                <TableCell align={'center'} component="th" scope="row">
                                                                                    {mydate.toLocaleDateString('fa-IR')}
                                                                                </TableCell>

                                                                                <TableCell align={'center'} component="th" scope="row">
                                                                                    <Link target="_blank"

                                                                                        href={`https://support.floriskorea.com/view/${item._id['$oid']}`}
                                                                                    >
                                                                                        Open Barcode
                                                                                    </Link>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        )
                                                                    })}

                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                        <Box>
                                                            {ListBarcode != null &&
                                                                <Pagination
                                                                    onChange={handleChangePage2}
                                                                    defaultPage={ListBarcode.page}
                                                                    count={ListBarcode.totalpage}
                                                                    color="primary"
                                                                />}
                                                        </Box>

                                                    </Box>
                                                    }
                                                </Box>
                                            </Container>
                                        </Box>
                                    }
                                    {
                                        value == 6 && <Box value="5">
                                            <Container key={key4}>
                                                <Box pt={2}>
                                                    <Typography variant="h5" color={'gray'}>ساخت بارکد </Typography>
                                                </Box>

                                                <Box minHeight={300} textAlign={'center'}>
                                                    <Stack spacing={10} direction={'row'}>
                                                        <Button
                                                            onClick={(e) => { createbarcode() }}
                                                            variant={'contained'} >ساخت بارکد</Button>
                                                        <Button
                                                            onClick={(e) => { reloadbarcode() }}
                                                            variant={'contained'} >دوباره خوانی</Button>

                                                    </Stack>
                                                    <Box>
                                                        <Grid container>
                                                            <Grid xs={12} sm={8}>
                                                                {Listofbarcode != null &&
                                                                    <TableContainer component={Paper}>
                                                                        <Table aria-label="caption table">
                                                                            <TableHead>
                                                                                <TableRow>
                                                                                    <TableCell align="right">ستون</TableCell>
                                                                                    <TableCell align={'center'}>تعداد</TableCell>
                                                                                    <TableCell align={'center'}>تاریخ</TableCell>
                                                                                    <TableCell align={'center'}>پرینت و PDF</TableCell>
                                                                                </TableRow>
                                                                            </TableHead>
                                                                            <TableBody>
                                                                                {Listofbarcode.message.docs.map((item, index) => {
                                                                                    var mydate = new Date(item.date['$date'])
                                                                                    return (
                                                                                        <TableRow >
                                                                                            <TableCell align="right" component="th" scope="row">
                                                                                                {index + 1}
                                                                                            </TableCell>
                                                                                            <TableCell align={'center'} component="th" scope="row">
                                                                                                {item.counter}
                                                                                            </TableCell>
                                                                                            {/* <TableCell align={'center'} component="th" scope="row">
                                                                                                {item.printed == false ? 'پرینت نشده' : 'پرینت شده'}
                                                                                            </TableCell> */}
                                                                                            <TableCell align={'center'} component="th" scope="row">
                                                                                                {mydate.toLocaleDateString('fa-IR')}
                                                                                            </TableCell>

                                                                                            <TableCell align={'center'} component="th" scope="row">
                                                                                                <Link target="_blank"

                                                                                                    onClick={(e) => { loadrpint(item._id['$oid']) }}
                                                                                                >
                                                                                                    <Print></Print>
                                                                                                </Link>
                                                                                            </TableCell>
                                                                                        </TableRow>

                                                                                    )
                                                                                })}
                                                                            </TableBody>
                                                                        </Table>
                                                                    </TableContainer>
                                                                }
                                                            </Grid>
                                                            <Grid xs={12} sm={3}>
                                                                <Box p={3}>
                                                                    {PDFS != null &&
                                                                        <iframe src={window.URL.createObjectURL(PDFS)} width={300} height={300} />
                                                                    }
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                    {PDF != null &&
                                                        <iframe
                                                            src={PDF}
                                                            width="100%"
                                                            height="600px"
                                                        />
                                                    }
                                                    <Box>
                                                        {Listofbarcode != null &&
                                                            <Pagination
                                                                onChange={handleChangePage2}
                                                                defaultPage={Listofbarcode.message.page}
                                                                count={Listofbarcode.message.totalpage}
                                                                color="primary"
                                                            />}
                                                    </Box>
                                                </Box>
                                            </Container>
                                        </Box>
                                    }
                                </TabContext>
                                <Box textAlign={'center'} pb={3}>
                                    <Button type="submit" variant={'contained'} > ذخیره سازی</Button>
                                </Box>
                            </Box>
                        </form>
                    }
                </Box>



            </Paper>
        </Container>
    )
}