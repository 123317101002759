import { Box, Breadcrumbs, Select, MenuItem, Button, Container, Grid, Link, Paper, Stack, TextField, Typography } from "@mui/material";
import { useForm } from 'react-hook-form';
import axios from "axios";
import { config } from "../../data/config";
import Swal from 'sweetalert2'
import { useCookies } from 'react-cookie';
import { useEffect } from "react";
import React from "react";
export default function NewUser() {
  const [cookies, setCookie] = useCookies(['token', 'rule']);
  const [listcat, setListCat] = React.useState(null);
  const [cateogry, selectCat] = React.useState(null);
  const [counter, setCounter] = React.useState(1);


  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const submit = async (data) => {
    var path = `${config.url}/estate`;
    var mydata = {
      name: data.name,
      cat: cateogry,
   

  };
    try {
      for (let i = 0; i < counter; i++) {

      let res = await axios({
        method: 'post',
        url: path,
        data: mydata,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': `application/json`,
          'Authorization': cookies.token
        }
      });
    } 
   //  window.location.href = '/dashboard/product/Edit/'+res.data.message;
   Swal.fire('عملیات با موفقیت ثبت شد')

   setCounter(1);
    } catch (error) {
      console.log('error')
      Swal.fire(error.response.data.message)
      error.response.data.error == null ?
        Swal.fire(error.response.data.message)
        : Swal.fire({
          title: error.response.data.message,
          html: `<div>
                ${Object.keys(error.response.data.error).map((field) => (
            ` <div key=${field}>
                 ${error.response.data.error[field].map((message) => (
              `<a style="font-size:15px"  key={message}> ${message}</a>`
            ))}
               </div>`
          ))}
              </div>`

        })
    }

  }
  const load = async () => {
    var path = `${config.url}/allcat`;
    try {
      let res = await axios({

        url: path,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': `multipart/form-data`,
        }

      });
      setListCat(res.data.message)
    } catch (error) {
      console.log(error);
      alert('error load group')
    }

  }
  useEffect(() => {
    load();
  }, []);
  return (
    <Container dir="rtl">
      <Box pb={3} pt={3}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/dashboard">
          داشبورد
          </Link>

          <Typography color="text.primary">مهمان جدید</Typography>
        </Breadcrumbs>
        <Typography variant='h1' color={'gray'} fontSize={34}>مهمان جدید</Typography>
      </Box>
      <Paper>
        <Box>
          <form onSubmit={handleSubmit((data) => submit(data))} >
            <Box p={4}
              borderRadius={2}>
              <Grid container>
               

                <Grid xs={12} md={6}>
                  <Box p={1}>
                    <label>طبقه*</label>
                    {listcat != null &&
                      <Select
                        fullWidth
                        required
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        onChange={(e) => { selectCat(e.target.value) }}

                      >
                        {listcat.map((item) => {
                          return (<MenuItem value={item._id.$oid}><Typography textAlign={'right'}>{item.name}</Typography></MenuItem>)
                        })}


                      </Select>

                    }
                    <label>نفرات</label>
                    <Box>
                    <TextField type="number" onChange={(e)=>{setCounter(e.target.value)}} value={counter}></TextField>

                    </Box>
                  </Box>
                </Grid>

              </Grid>
              <Box pt={6}>
                <Stack direction={'row'}>
                  <Box m={1}>
                    <Button href="/dashboard" variant={'outlined'}>بازگشت</Button>
                  </Box>
                  <Box m={1}>
                    <Button type="submit" variant={'contained'}>اضافه کردن</Button>
                  </Box>

                </Stack>

              </Box>
            </Box>
          </form>
        </Box>
      </Paper>
    </Container>
  )
}