import { Container, Box, Breadcrumbs, Link, Typography, Paper, Button } from "@mui/material";
import axios from "axios";
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { config } from "../../data/config";

export default function BarcodeList() {
    let { Id } = useParams();
    const [cookies, setCookie] = useCookies(['token', 'rule']);

    const [Listofbarcode, setListofbarcode] = React.useState(null);
    const loadrpint = async (id) => {
        var path = `${config.url}/barcodeapi/${Id}`;
        try {
            let res = await axios({
                method: 'get',
                data: {},
                url: path,
                responseType: 'blob', // had to add this one here

                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': `application/json`,
                    'Authorization': cookies.token,
                }
            });

            setListofbarcode(res.data)

        } catch (error) {

        };
    }
    return (
        <>
            <Container dir={'rtl'}>
                <Box pb={3} pt={3}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" href="/dashboard">
                            داشبورد
                        </Link>

                        <Typography color="text.primary">لیست بارکدها</Typography>
                    </Breadcrumbs>
                    <Typography variant='h1' color={'gray'} fontSize={34}>لیست بارکدها</Typography>
                </Box>
                <Paper>
                    <Box p={2}>
                        <Button onClick={(e)=>{loadrpint()}}>دوباره خوانی</Button>
                    </Box>
                </Paper>

            </Container>

        </>
    )

}