import { Avatar, Badge, Box, Button, Card, CardMedia, Container, Grid, Link, Stack, Typography } from "@mui/material";
import { useEffect } from 'react';
import { config } from "../data/config";
import * as React from 'react';
import Swal from 'sweetalert2'
import { useParams } from 'react-router-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Instagram } from "@mui/icons-material";

import axios from "axios";
import { Bed, Build, CancelPresentation, Check, CopyAll, Email, Facebook, LinkedIn, LocationCity, Mail, Money, Twitter } from "@mui/icons-material";

export default function SharePage() {
    console.log("###? App()");

    const [data, setData] = React.useState(null);
    const [cat, setCat] = React.useState(null);
    const [mainimage, setImage] = React.useState(null);
    const [city, setCity] = React.useState(null);
    const [aria, setAria] = React.useState(null);
    const [Neightbor, setNeightbor] = React.useState(null);
    const [Gallery, setGallery] = React.useState(null);
    const [Feature, setFeature] = React.useState(null);
    const [header, setHeader] = React.useState(null);
    const [avatar, setAvatar] = React.useState(null);
    const [check, setCheck] = React.useState(false);
    const [website, setwebsite] = React.useState('http://localhost:3000/detail/');
    const dataFetchedRef = React.useRef(false);

    let { Id } = useParams();


    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        load();

    }, []);
    const load = async () => {
        console.log('Fetching data...');

        if (check == false) {
            setCheck(true);
            try {
                var path = `${config.url}/detail/${Id}`;
                let res = await axios({
                    method: 'get',
                    url: path,

                });
                setData(res.data.message)
                setAvatar(res.data.mainimage)
                setCat(res.data.product)
                setwebsite(res.data.setting)
                setHeader(res.data.settingimage)


            } catch (error) {
                Swal.fire('No access edit this estate')

            }
        }

    }

    return (
        <Box bgcolor={'white'} >


            <Box width={'100%'} textAlign={'center'}>
                <Box>

                </Box>
                {
                    header != null &&
                    <Box pt={1}>

                        <img
                            height={300}
                            width='100%'
                            style={{
                                height: '300px !important',
                                objectFit: 'cover',
                                objectPosition: 'top',
                                backgroundAttachment: 'fixed',
                                borderRadius: '15px'
                            }}


                            src={config.url + '/' + header.url}></img>
                    </Box>
                }
                <Box width={'400px'} bgcolor={'white'} p={6} marginLeft={'auto'} marginRight={'auto'} position={'relative'}>
                    {data != null &&
                        <Box>
                            {data.countview != 0 ?
                                <Typography fontSize={'40px'} color={'green'}> VERIFIED</Typography>
                                :
                                <Box>
                                    <Typography fontSize={'40px'} color={'red'}> NOT VERIFIED!</Typography>
                                    <Typography color={'black'}>I'm sorry! This product is not valid.</Typography>
                                </Box>
                            }

                            <Box
                                width={'100%'}
                                bgcolor='white'
                                zIndex={4000}

                            >
                                <Box bgcolor={'white'} color={'black'}>
                                    {cat != null &&
                                        <Box

                                        >

                                            {
                                                avatar != null &&
                                                <Box>
                                                    <Box >
                                                        <img height={300}

                                                            style={{ objectFit: 'contain', borderRadius: '15px' }}
                                                            width={'100%'} src={config.url + '/' + avatar.url}></img>
                                                    </Box>
                                                </Box>
                                            }
                                            <Box p={2}>
                                              
                                               <Box mt={2} mb={2} sx={{border:'solid 1px #eee',padding:3,borderRadius:4}}>  
                                               <Typography textAlign={'center'} fontFamily={'arial'} color={'darkblue'}>{cat.name}</Typography>
                                               {cat.year != null && cat.bed != "" &&
                                                    <Typography textAlign={'left'} fontFamily={'arial'} fontSize={12} color={'black'}>
                                                        Code:{cat.bed} </Typography>
                                                }
                                                {cat.year != null && cat.year != "" &&
                                                    <Typography textAlign={'left'} fontFamily={'arial'} fontSize={12} color={'black'}>
                                                        Brand:{cat.year} </Typography>
                                                }
                                                {cat.price != null && cat.price != "" &&
                                                    <Typography textAlign={'left'} fontFamily={'arial'} fontSize={12} color={'black'}>
                                                        Price:{cat.price} $</Typography>
                                                }
                                                 {cat.text != null && cat.text != "" &&
                                                  <>
                                                    <Typography textAlign={'left'} fontSize={12}>Description:</Typography>
                                                  <Typography textAlign={'left'} 
                                                    
                                                    dangerouslySetInnerHTML={{ __html: cat.text }} 
                                                    fontFamily={'arial'} fontSize={10} color={'black'}>
                                                       </Typography>
                                                  </>
                                                }
                                                </Box>
                                               
                                            </Box>
                                            {
                                                data != null &&
                                                <Box>

                                                    <Box>
                                                        <Typography>Remaining Validation details ({data.countview}/3):</Typography>
                                                    </Box>
                                                    <Box width={'100%'} >

                                                        <Box textAlign={'center'} width={'150px'} marginRight={'auto'} marginLeft={'auto'}>
                                                            {
                                                                data.countview == 0 &&
                                                                <>
                                                                    <BoxVerify2 data={data.countview} current={1}></BoxVerify2>
                                                                    <BoxVerify2 data={data.countview} current={2}></BoxVerify2>
                                                                    <BoxVerify2 data={data.countview} current={3}></BoxVerify2>
                                                                </>
                                                            }
                                                            {
                                                                data.countview == 3 &&
                                                                <>
                                                                    <BoxVerify2 data={data.countview} current={1}></BoxVerify2>
                                                                </>
                                                            }
                                                            {
                                                                data.countview == 2 &&
                                                                <>

                                                                    <BoxVerify2 data={data.countview} current={1}></BoxVerify2>
                                                                    <BoxVerify2 data={data.countview} current={2}></BoxVerify2>

                                                                </>
                                                            }
                                                            {
                                                                data.countview == 1 &&
                                                                <>
                                                                    <BoxVerify2 data={data.countview} current={1}></BoxVerify2>
                                                                    <BoxVerify2 data={data.countview} current={2}></BoxVerify2>

                                                                    <BoxVerify2 data={data.countview} current={3}></BoxVerify2>
                                                                </>
                                                            }

                                                        </Box>
                                                    </Box>

                                                </Box>
                                            }

                                        </Box>

                                    }
                                </Box>

                            </Box>
                        </Box>
                    }
                </Box>

            </Box>
            <Box mt={10} mb={10} textAlign={'center'} width={'100px'} marginRight={'auto'} marginLeft={'auto'}>

                <Typography fontSize={10}>support.floriskorea.com</Typography>
            </Box>
        </Box>
    )
}
function BoxVerify({ data, current }) {
    return (
        <Box marginRight={'auto'} marginLeft={'auto'} mt={1} >
            <Grid container>
                <Grid xs={3}>
                    <Box border={`solid 1px ${data + 1 > current ? 'lightgreen' : 'red'} `}
                        width={30}
                        height={30}
                        mt={1}
                        pt={0.3}
                        borderRadius={'90%'}
                        marginRight={'auto'}
                        marginLeft={'auto'}>
                        <Typography color={data + 1 > current ? 'lightgreen' : 'red'}>{current}</Typography>
                    </Box>
                </Grid>
                <Grid xs={8}>
                    <Typography fontSize={14} textAlign={'left'}>Verify {current}</Typography>
                    <Typography fontSize={13} textAlign={'left'} color={data + 1 > current ? 'lightgreen' : 'red'}>
                        {data + 1 > current ? 'Accepted' : 'Reject'}
                    </Typography>

                </Grid>


            </Grid>
        </Box>
    )
}
function BoxVerify2({ current }) {
    return (
        <Box marginRight={'auto'} marginLeft={'auto'} mt={1} >
            <Grid container>
                <Grid xs={3}>
                    <Box border={`solid 1px lightgreen`}
                        width={30}
                        height={30}
                        mt={1}
                        pt={0.3}
                        borderRadius={'90%'}
                        marginRight={'auto'}
                        marginLeft={'auto'}
                    >
                        <Typography color={'lightgreen'}>{current}</Typography>
                    </Box>
                </Grid>
                <Grid xs={8}>
                    <Typography fontSize={14} textAlign={'left'}>Verify {current}</Typography>
                    <Typography fontSize={13} textAlign={'left'} color={'lightgreen'}>
                        Accepted
                    </Typography>

                </Grid>


            </Grid>
        </Box>
    )
}