
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, Container, Grid, Typography, Link } from '@mui/material';
import { useCookies } from 'react-cookie';
import axios from "axios";
import { config } from "../../data/config";
import Swal from 'sweetalert2'
import { Edit, DeleteForever, Star } from '@mui/icons-material';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { useEffect } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}



export default function CategoryList() {
    const [cookies, setCookie] = useCookies(['token', 'rule']);
    const [page, setPage] = React.useState(1);
    const [data, setData] = React.useState(null);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        reload()
    };
    const deleteitem = async(id)=>{
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
              confirmButton: "btn btn-success",
              cancelButton: "btn btn-danger"
            },
            buttonsStyling: false
          });
          Swal.fire({
            title: "مطمئن هستید",
            text: "این گروه پاک شود؟",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "'به پاک شود'",
            cancelButtonText: "نه",
            reverseButtons: true
          }).then(async (result) => {
            if (result.isConfirmed) {
                var path = `${config.url}/cat/${id}`;
                try {
                    let res = await axios({
                        method: 'delete',
                        url: path,
                        headers: {
                            "Access-Control-Allow-Origin": "*",
                            "Access-Control-Allow-Methods": "*",
                            'Authorization': cookies.token
                        }
                    });
                   
                    reload();
        
        
                } catch (error) {
                    console.log(error)
                }
            } else if (
              /* Read more about handling dismissals below */
              result.dismiss === Swal.DismissReason.cancel
            ) {
             
            }
          });
       
    }
    const reload = async () => {
        var path = `${config.url}/cat?limit=10&page=${page}`;
        try {
            let res = await axios({
                method: 'get',
                url: path,
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "*",
                    'Authorization': cookies.token
                }
            });
            setData(res.data)

        } catch (error) {
            console.log(error)
        }

    }
    useEffect(() => {
        reload();
    }, []);
    return (
        <Box dir="rtl">

            <Container>
                <Box pb={3} pt={3}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" href="/dashboard">
داشبورد                
                        </Link>

                        <Typography color="text.primary">لیست</Typography>
                    </Breadcrumbs>
                    <Typography variant='h1' color={'gray'} fontSize={34}>لیست طبقه ها</Typography>
                </Box>
                <Paper >
                    <Container>
                        <Box pt={4}>
                            <Stack direction={'row'} spacing={4}>
                                <Box p={1}>
                                    <Button variant={'contained'} href='/dashboard/category/new'>طیقه جدید</Button>
                                </Box>
                                <Box p={1}>
                                    <Button variant={'contained'} onClick={(e) => { reload() }}>دوباره خوانی</Button>
                                </Box>
                            </Stack>
                        </Box>
                    </Container>
                    {
                        data != null &&
                        <Box pt={4} p={4}>

                            <TableContainer component={Paper}>

                                <Table sx={{ minWidth: 650 }} aria-label="caption table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell  align='left'>نام </TableCell>
                                            <TableCell  align='center'>فعالیت </TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        {data.docs.map((row) => (
                                            <TableRow key={row.name}>
                                                <TableCell component="th" scope="row"  align='left'>
                                                    {row.name} {row.rule == 1 && <Star fontSize='small' color='primary'></Star>}
                                                </TableCell>
                                                <TableCell  align='center' >
                                                        <Link href={`/dashboard/category/edit/${row._id['$oid']}`} underline='none' color={'#333'}>
                                                            <Edit />

                                                        </Link>
                                                        <Button onClick={(e)=>{ deleteitem(row._id['$oid'])}}>
                                                        <DeleteForever />
                                                        </Button>

                                                    

                                                 
                                                </TableCell>

                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Box p={2}>
                                <Pagination
                                    onChange={handleChangePage}
                                    defaultPage={data.page}
                                    count={data.totalpage}
                                    color="primary"
                                />

                            </Box>


                        </Box>
                    }
                </Paper>
            </Container>
        </Box>
    );
}