import { Box, Typography } from "@mui/material";
import { Outlet, Link } from "react-router-dom";

import PrimarySearchAppBar from "../component/headerAdmin";
import { AddBoxOutlined } from "@mui/icons-material";
import { ThemeProvider, createTheme } from '@mui/material/styles';

const darkTheme = createTheme({
    palette: {
      mode: 'light',
    },
  });
export default function DashboardLayout() {
    return (
        <div className="App">
            <div >
                <header className="App-header">
                    <PrimarySearchAppBar></PrimarySearchAppBar>
                    <ThemeProvider theme={darkTheme}>
            <div style={{ backgroundColor: '#E4E6EB', height: '90vh' }}>
                        <Outlet></Outlet>

                    </div>
            </ThemeProvider>
                   
                    <footer style={{ color: "gray", position: "fixed", bottom: 0, width: '100%', textAlign: 'center' }}>
                        <right><a textAlign={'center'} style={{ fontSize: 10 }}></a></right>
                    </footer>
                </header>
            </div>
        </div>
    )
}