export const Mneuitem=[
    {
      url:'/dashboard/product',
      name:'مهمان جدید',
      icon:'/assets/add.svg',
      per:[1,2]
    },
   
    {
      url:'/dashboard/list',
      name:' عملیات فروش',
      icon:'/assets/list.svg',

      per:[1,3]

    },
   
    {
      url:'/dashboard/category',
      name:'طبقه ها',
      icon:'/assets/folder.svg',
      per:[1]
    },
    {
      url:'/dashboard/setting',
      name:'تنظیمات',
      icon:'/assets/setting.svg',
      per:[1]
    },
    
    {
      url:'/dashboard/users',
      name:'کاربران',
      icon:'/assets/users.svg',
      per:[1]
    },
    
    {
      url:'/logout',
      name:'خروج',
      icon:'/logout.svg',
      per:[1,2,3]
    }
  ]