
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, Container, Grid, Typography, Link, Select, MenuItem, TextField } from '@mui/material';
import { useCookies } from 'react-cookie';
import axios from "axios";
import { config } from "../../data/config";
import Swal from 'sweetalert2'
import { Edit, DeleteForever, Star, Map, ArrowUpward, ArrowDownward, Share } from '@mui/icons-material';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { useEffect } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { useParams } from 'react-router-dom';

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}



export default function ListEstate() {
    let { Id, Parent } = useParams();

    const [cookies, setCookie] = useCookies(['token', 'rule']);
    const [page, setPage] = React.useState(1);
    const [data, setData] = React.useState(null);
    const [cat, setCat] = React.useState(null);
    const [listcat, setListCat] = React.useState(null);
    const [cateogry, selectCat] = React.useState(null);
    const [success, setSuccess] = React.useState(null);
    const [invoice, setInvoice] = React.useState(null);
    const [message, setMessage] = React.useState(null);
    const [timer, setTimer] = React.useState(null);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        reload()
    };
    const totop = async (item) => {
        try {
            var path = `${config.url}/neighborhood/totop/${item}`;
            let res = await axios({
                method: 'put',
                url: path,
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': `multipart/form-data`,
                    'Authorization': cookies.token
                }
            });
            reload()
            // setData(res.data.message)
        } catch (error) {
        }
    }
    const todown = async (item) => {
        try {
            var path = `${config.url}/neighborhood/todown/${item}`;
            let res = await axios({
                method: 'put',
                url: path,
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': `multipart/form-data`,
                    'Authorization': cookies.token
                }
            });
            reload()
            // setData(res.data.message)
        } catch (error) {
        }
    }

    const deleteforover = async (e) => {
        try {
            var path = `${config.url}/estate/${e}`;
            let res = await axios({
                method: 'delete',
                url: path,
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': `multipart/form-data`,
                    'Authorization': cookies.token
                }
            });
            reload()
            // setData(res.data.message)
        } catch (error) {
            Swal.fire('can not be delete iteam')
        }
    }
    const reload = async () => {
        var path = `${config.url}/estate?limit=10&page=${page}&parent=${Id}`;
        try {
            let res = await axios({
                method: 'get',
                url: path,
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "*",
                    'Authorization': cookies.token
                }
            });
            setData(res.data)


        } catch (error) {
            console.log(error)
        }

    }
    const Save = async () => {
            var path = `${config.url}/estate/2`;
            var mydata = {
            category: cateogry,
             success: success,
             invoice:invoice,
             message:message,
             timer:timer
          };
          try {
            let res = await axios({
              method: 'put',
              url: path,
              data: mydata,
              headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': `application/json`,
                'Authorization': cookies.token
              }
            });
        reload();
        loadcat();
         //  window.location.href = '/dashboard/product/Edit/'+res.data.message;
         Swal.fire('عملیات با موفقیت ثبت شد')
      
      
          } catch (error) {
            console.log('error')
            Swal.fire(error.response.data.message)
            error.response.data.error == null ?
              Swal.fire(error.response.data.message)
              : Swal.fire({
                title: error.response.data.message,
                html: `<div>
                      ${Object.keys(error.response.data.error).map((field) => (
                  ` <div key=${field}>
                       ${error.response.data.error[field].map((message) => (
                    `<a style="font-size:15px"  key={message}> ${message}</a>`
                  ))}
                     </div>`
                ))}
                    </div>`
      
              })
          }
      
    }
    const loadcat = async () => {
        var path = `${config.url}/allcat`;
        try {
            let res = await axios({

                url: path,
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': `multipart/form-data`,
                }

            });
            setListCat(res.data.message)
        } catch (error) {
            console.log(error);
            alert('error load group')
        }

    }
    useEffect(() => {
        reload();
        loadcat();
    }, []);
    return (
        <Box dir="rtl">

            <Container>
                <Box pb={3} pt={3}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" href="/dashboard">
                            داشبورد
                        </Link>
                        <Typography color="text.primary">لیست </Typography>
                    </Breadcrumbs>
                    <Typography variant='h1' color={'gray'} fontSize={34}>
                        لیست
                    </Typography>
                </Box>
                <Paper >
                    <Container>
                        <Box pt={4}>
                            <Stack direction={'row'} spacing={1}>
                                {
                                    cat != null && <Button variant={'contained'} href={`/dashboard/neighborhood/add/${Id}/${Parent}`}>Add New Neighborhood</Button>
                                }

                                <Button variant={'contained'} onClick={(e) => { reload() }}>دوباره خوانی</Button>
                            </Stack>
                        </Box>
                    </Container>
                    {data != null &&
                        <Grid p={1} container spacing={2}>
                            {
                                data.message.map((row) => (
                                    <Grid item xs={4} >
                                        <Box p={2} bgcolor={'#eee'} borderRadius={3}>
                                            <Box>طبقه : {row.cat}</Box>
                                            <Box>تعداد : {row.total}</Box>
                                            <Box>ویزیت شده ها : {row.visisted}</Box>
                                        </Box>

                                    </Grid>


                                ))
                            }


                        </Grid>
                    }
                    <Box>

                        {
                            listcat != null && <Box>
                                <form>
                                    <Box p={1}>
                                        <Grid container spacing={2} >
                                            <Grid item xs={4} >
                                                <label>طبقه*</label>

                                                {listcat != null &&
                                                    <Select
                                                        fullWidth
                                                        required
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        onChange={(e) => { selectCat(e.target.value) }}

                                                    >
                                                        {listcat.map((item) => {
                                                            return (<MenuItem value={item._id.$oid}><Typography textAlign={'right'}>{item.name}</Typography></MenuItem>)
                                                        })}


                                                    </Select>

                                                }
                                            </Grid>
                                            <Grid item xs={4} >
                                                <label>موفق*</label>
                                                <Box>

                                                    <Select
                                                        onChange={(e) => { setSuccess(e.target.value) }}

                                                        fullWidth>
                                                        <MenuItem value={true}>بله</MenuItem>
                                                        <MenuItem value={false}>خیر</MenuItem>

                                                    </Select>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={4} >
                                                <label>شماره فاکتور*</label>
                                                <Box>

                                                    <TextField
                                                        onChange={(e) => { setInvoice(e.target.value) }}

                                                        fullWidth></TextField>

                                                </Box>
                                            </Grid>
                                            <Grid item xs={4} >
                                                <label>دلیل عدم خرید*</label>
                                                <Box>

                                                    <Select
                                                        onChange={(e) => { setMessage(e.target.value) }}


                                                        fullWidth>
                                                        <MenuItem value={'موجود نبودن کالا در انبار'}>موجود نبودن کالا در انبار</MenuItem>
                                                        <MenuItem value={'گران بودن کالا در انبار'}>گران بودن کالا در انبار</MenuItem>
                                                        <MenuItem value={'منصرف شدن مشتری'}>منصرف شدن مشتری</MenuItem>

                                                    </Select>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <label>مدت زمان صحبت با مشتری به دقیقه</label>
                                                <TextField
                                                    onChange={(e) => { setTimer(e.target.value) }}

                                                    type='number' fullWidth></TextField>
                                            </Grid>
                                        </Grid>
                                        <Box m={3}>
                                            <Button onClick={(e) => Save()} variant='contained'>ذخیره سازی</Button>

                                        </Box>

                                    </Box>
                                </form>
                            </Box>
                        }
                    </Box>
                </Paper>
            </Container>
        </Box>
    );
}